/* eslint-disable func-names */
// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require */

module.exports = function deviceHelpersFactory(deps = {}) {
  const getNativeEnumerateDevices = deps.getNativeEnumerateDevices || require('./getNativeEnumerateDevices.js')();
  const env = deps.env || require('./env');

  const deviceHelpers = {};

  // /
  // Device Helpers
  //
  // Support functions to enumerating and guerying device info
  //

  const deviceKindsMap = {
    audio: 'audioInput',
    video: 'videoInput',
    audioinput: 'audioInput',
    videoinput: 'videoInput',
    audioInput: 'audioInput',
    videoInput: 'videoInput',
  };

  const enumerateDevices = function () {
    // Our mocking currently requires that this be re-evaluated each time.
    const fn = getNativeEnumerateDevices();
    return fn();
  };

  // Indicates whether this browser supports the enumerateDevices (getSources) API.
  //
  deviceHelpers.hasEnumerateDevicesCapability = function () {
    return typeof getNativeEnumerateDevices() === 'function';
  };

  // OPENTOK-40733, see https://bugs.webkit.org/show_bug.cgi?id=209739#c2
  // multiple calls to enumerateDevices fail to return video devices
  // note that this causes any devices without a camera (mac minis) to make a video gum call
  // we believe this is worth it to prevent safari video breaking
  deviceHelpers.hasEnumerateDevicesBug = () => env.isSafari && env.version === 13.1;

  deviceHelpers.getMediaDevices = function () {
    if (!deviceHelpers.hasEnumerateDevicesCapability()) {
      return Promise.reject(
        new Error('This browser does not support enumerateDevices APIs')
      );
    }

    return enumerateDevices()
      .then(devices =>
        // Normalise the device kinds
        devices.map(device => ({
          deviceId: device.deviceId || device.id,
          label: device.label,
          kind: deviceKindsMap[device.kind],
        })).filter(device => device.kind === 'audioInput' || device.kind === 'videoInput'));
  };

  deviceHelpers.shouldAskForDevices = function () {
    if (!deviceHelpers.hasEnumerateDevicesCapability()) {
      // Assume video and audio exists if enumerateDevices is not available
      return Promise.resolve({ video: true, audio: true });
    }

    return deviceHelpers.getMediaDevices()
      .then((devices) => {
        if (devices.length === 0) {
          // If no devices reported, might as well try anyway, maybe it was just an issue with
          // device enumeration.
          return { video: true, audio: true };
        }
        if (deviceHelpers.hasEnumerateDevicesBug()) {
          return {
            audio: true, video: true,
          };
        }

        const audioDevices = devices.filter(device => device.kind === 'audioInput');
        const videoDevices = devices.filter(device => device.kind === 'videoInput');

        return {
          video: videoDevices.length > 0,
          audio: audioDevices.length > 0,
          videoDevices,
          audioDevices,
        };
      })
      .catch(() =>
        // There was an error. It may be temporally. Just assume
        // all devices exist for now.
        ({ video: true, audio: true }));
  };

  return deviceHelpers;
};
