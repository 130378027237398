const env = require('../../helpers/env.js');
const toArray = require('lodash/toArray');

/**
 * @returns {function(RTCPeerConnection, function(DOMError, Array<RTCStats>))}
 */
module.exports = function getStatsAdapter() {
  // /
  // Get Stats using the older API. Used by all current versions
  // of Chrome.
  //
  function getStatsOldAPI(peerConnection, completion) {
    peerConnection.getStats((rtcStatsReport) => {
      const stats = [];
      rtcStatsReport.result().forEach((rtcStat) => {
        const stat = {};

        rtcStat.names().forEach((name) => {
          stat[name] = rtcStat.stat(name);
        });

        // fake the structure of the "new" RTC stat object
        stat.id = rtcStat.id;
        stat.type = rtcStat.type;
        stat.timestamp = rtcStat.timestamp;
        stats.push(stat);
      });

      completion(null, stats);
    });
  }

  // /
  // Get Stats using the newer API.
  //
  function getStatsNewAPI(peerConnection, completion) {
    peerConnection.getStats(null)
      .then((rtcStatsReport) => {
        let stats = [];
        // Firefox <= 45 can't use rtcStatsReport.values OPENTOK-32755
        if (typeof rtcStatsReport.values === 'function') {
          stats = toArray(rtcStatsReport.values());
        } else if (typeof rtcStatsReport.forEach !== 'function') {
          stats = Object.keys(rtcStatsReport).map(key => rtcStatsReport[key]);
        } else {
          rtcStatsReport.forEach((rtcStats) => {
            stats.push(rtcStats);
          });
        }
        completion(null, stats);
      }).catch(completion);
  }

  if (['Firefox', 'Edge', 'Safari'].indexOf(env.name) !== -1) {
    return getStatsNewAPI;
  }

  return getStatsOldAPI;
};
