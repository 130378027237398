// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, no-underscore-dangle */
/* eslint-disable no-use-before-define, no-shadow, max-len */

const defaults = require('lodash/defaults');
const merge = require('lodash/merge');
const assign = require('lodash/assign');
const values = require('lodash/values');
const last = require('lodash/last');
const get = require('lodash/get');
const once = require('lodash/once');
const { CancellationError } = require('cancel');
const uuid = require('uuid');
const EventEmitter = require('events');

const now = require('../../helpers/now.js');
const castToBoolean = require('../../helpers/castToBoolean.js');
const eventNames = require('../../helpers/eventNames');
const eventing = require('../../helpers/eventing');
const waitUntil = require('../../helpers/waitUntil');
const RafRunner = require('../RafRunner');
const eventHelper = require('../../helpers/eventHelper');
const interpretSubscriberCreateError = require('./interpretSubscriberCreateError');
const Errors = require('../Errors');
const defaultWidgetView = require('../../helpers/widget_view')();
const audioLevelBehaviour = require('./audioLevelBehaviour');

const audioContextDefault = require('../../helpers/audio_context.js')();
const AudioLevelMeterDefault = require('../chrome/audio_level_meter.js');
const AudioLevelTransformerDefault = require('../audio_level_transformer');
const BackingBarDefault = require('../chrome/backing_bar.js');
const ChromeDefault = require('../chrome/chrome.js');
const envDefault = require('../../helpers/env.js');
const errorsDefault = require('../Errors.js');
const EventsDefault = require('../events.js')();
const ExceptionCodesDefault = require('../exception_codes.js');
const GetStatsAudioLevelSamplerDefault = require('../../helpers/audio_level_samplers/getstats_audio_output_level_sampler');
const getStatsHelpersDefault = require('../peer_connection/get_stats_helpers.js');
const hasAudioOutputLevelStatCapabilityDefault = require('../../helpers/hasAudioOutputLevelStatCapability.js');
const hasRemoteStreamsWithWebAudioDefault = require('../../helpers/hasRemoteStreamsWithWebAudio.js');
const interpretPeerConnectionErrorDefault = require('../interpretPeerConnectionError.js')();
const loggingDefault = require('../../helpers/log')('Subscriber');
const MuteButtonDefault = require('../chrome/mute_button.js')();
const NamePanelDefault = require('../chrome/name_panel.js');
const otErrorDefault = require('../../helpers/otError.js')();
const OTErrorClassDefault = require('../ot_error_class.js');
const OTHelpersDefault = require('../../common-js-helpers/OTHelpers.js');
const StylableComponentDefault = require('../styling/stylable_component.js');
const windowMock = require('../../helpers/createWindowMock')(global);
const PeerConnection = require('../peer_connection/peer_connection')({
  global: windowMock,
});
const SubscriberPeerConnectionDefault = require('../peer_connection/subscriber_peer_connection.js')({
  PeerConnection,
});
const SubscribingStateDefault = require('./state.js');
const VideoDisabledIndicatorDefault = require('../chrome/video_disabled_indicator.js');
const AudioBlockedIndicatorDefault = require('../chrome/AudioBlockedIndicator.js');
const VideoUnsupportedIndicatorDefault = require('../chrome/VideoUnsupportedIndicator.js');
const watchFrameRateDefault = require('../peer_connection/watchFrameRate.js');
const WebAudioLevelSamplerDefault = require('../../helpers/audio_level_samplers/webaudio_audio_level_sampler');
const createSendMethodDefault = require('../session/createSendMethod');
const parseIceServersDefault = require('../../RaptorSession/raptor/parseIceServers.js').parseIceServers;
const overallTimeout = require('../../helpers/overallTimeout');
const createConnectivityState = require('../../helpers/connectivityState');
const createConnectivityAttemptPinger = require('../../helpers/connectivityAttemptPinger');

const DEFAULT_AUDIO_VOLUME = 100;

const isLocalStream = (stream, session) => stream.connection.id === session.connection.id;

const constructSubscriberUri = ({ apiKey, sessionId, streamId, subscriberId }) => [
  '/v2/partner', apiKey, 'session', sessionId, 'stream', streamId, 'subscriber', subscriberId,
].join('/');

function hasExpectedTracks(mediaStream, oTStream) {
  const isMissingVideo = (
    oTStream && oTStream.hasVideo &&
    mediaStream && mediaStream.getVideoTracks().length === 0
  );
  const isMissingAudio = (
    oTStream && oTStream.hasAudio &&
    mediaStream && mediaStream.getAudioTracks().length === 0
  );

  return !(isMissingVideo || isMissingAudio);
}

function normalizeAudioVolume(volume) {
  return Math.max(0, Math.min(100, parseInt(volume, 10)));
}

function determineAudioVolume(props) {
  if (props.audioVolume !== undefined) {
    return props.audioVolume;
  } else if (props.subscribeToAudio === false) {
    return 0;
  }
  return DEFAULT_AUDIO_VOLUME;
}

module.exports = function SubscriberFactory({
  audioContext = audioContextDefault,
  AudioLevelMeter = AudioLevelMeterDefault,
  AudioLevelTransformer = AudioLevelTransformerDefault,
  BackingBar = BackingBarDefault,
  Chrome = ChromeDefault,
  env = envDefault,
  Errors: errors = errorsDefault,
  Events = EventsDefault,
  ExceptionCodes = ExceptionCodesDefault,
  GetstatsAudioOutputLevelSampler: GetStatsAudioOutputLevelSampler = GetStatsAudioLevelSamplerDefault,
  getStatsHelpers = getStatsHelpersDefault,
  hasAudioOutputLevelStatCapability = hasAudioOutputLevelStatCapabilityDefault,
  hasRemoteStreamsWithWebAudio = hasRemoteStreamsWithWebAudioDefault,
  interpretPeerConnectionError = interpretPeerConnectionErrorDefault,
  logging = loggingDefault,
  MuteButton = MuteButtonDefault,
  NamePanel = NamePanelDefault,
  otError = otErrorDefault,
  OTErrorClass = OTErrorClassDefault,
  OTHelpers = OTHelpersDefault,
  StylableComponent = StylableComponentDefault,
  SubscriberPeerConnection = SubscriberPeerConnectionDefault,
  SubscribingState = SubscribingStateDefault,
  VideoDisabledIndicator = VideoDisabledIndicatorDefault,
  AudioBlockedIndicator = AudioBlockedIndicatorDefault,
  VideoUnsupportedIndicator = VideoUnsupportedIndicatorDefault,
  watchFrameRate = watchFrameRateDefault,
  WebaudioAudioLevelSampler: WebAudioAudioLevelSampler = WebAudioLevelSamplerDefault,
  createSendMethod = createSendMethodDefault,
  parseIceServers = parseIceServersDefault,
  document = global.document,
  WidgetView = defaultWidgetView,
} = {}) {
  const BIND_VIDEO_DELAY_MAX = 30000;
  const BIND_VIDEO_DELAY_WARNING = 15000;

  /**
   * The Subscriber object is a representation of the local video element that is playing back
   * a remote stream. The Subscriber object includes methods that let you disable and enable
   * local audio playback for the subscribed stream. The <code>subscribe()</code> method of the
   * {@link Session} object returns a Subscriber object.
   *
   * @property {Element} element The HTML DOM element containing the Subscriber.
   * @property {String} id The DOM ID of the Subscriber.
   * @property {Stream} stream The stream to which you are subscribing.
   *
   * @class Subscriber
   * @augments EventDispatcher
   */
  const Subscriber = function Subscriber(targetElement, options = {}, completionHandler = () => { }) {
    if (options.analytics === undefined) {
      // @todo does anyone instantiate a Subscriber outside of `session.subscribe`?
      // it might be best to instantiate an analytics object and replace it on subscribe like
      // we do in the publisher.
      throw new Error('Subscriber requires an instance of analytics');
    }

    /** @type AnalyticsHelperDefault */
    const analytics = options.analytics;
    const _widgetId = uuid();
    const _audioLevelCapable = Subscriber.hasAudioOutputLevelStatCapability() || hasRemoteStreamsWithWebAudio();
    const _subscriber = this;
    const _pcConnected = {};
    const peerConnections = {};

    /** @type {defaultWidgetView|null} */
    let _widgetView;
    let _chrome;
    let _muteDisplayMode;
    let _audioLevelMeter;
    let _subscribeStartTime;
    let _state;
    let _audioLevelSampler;
    let destroyAudioLevelBehaviour;
    let _webRTCStream;
    let _lastSubscribeToVideoReason;
    let _attemptStartTime;
    /** @type IntervalRunnerDefault | undefined */
    let _streamEventHandlers;
    let _isSubscribingToAudio;
    let _loaded = false;
    let _domId = targetElement || _widgetId;
    let _session = options.session;
    let _stream = options.stream;
    let _audioVolume;
    let _latestPositiveVolume;
    let _frameRateRestricted = false;
    let _frameRateWatcher;
    let _lastIceConnectionState = eventNames.SUBSCRIBER_DISCONNECTED;
    let _preDisconnectStats = {};
    let _congestionLevel = null;
    let _hasLoadedAtLeastOnce = false;
    let _isVideoSupported = true;
    let fallbackIceServers = [];
    let _properties = defaults({}, options, {
      showControls: true,
      testNetwork: false,
      fitMode: _stream.defaultFitMode || 'cover',
      insertDefaultUI: true,
    });
    let _currentPeerConnectionEvents;

    const socket = _session._.getSocket();

    const getAllPeerConnections = () => values(peerConnections);
    const getPeerConnectionByPriority = priority => peerConnections[priority];
    const getMaxPriority = () => last(Object.keys(peerConnections).map(Number).sort());
    const getPriorityPeerConnection = () => getPeerConnectionByPriority(getMaxPriority());
    const removePeerConnectionByPromise = (futurePc) => {
      Object.keys(peerConnections).forEach((key) => {
        if (peerConnections[key] === futurePc) {
          delete peerConnections[key];
        }
      });
    };

    const logAnalyticsEvent = (action, variation, payload, options, throttle) => {
      let stats = assign({
        action,
        variation,
        payload,
        streamId: _stream ? _stream.id : null,
        sessionId: _session ? _session.sessionId : null,
        connectionId: (_session && _session.isConnected()) ? _session.connection.connectionId : null,
        partnerId: (_session && _session.sessionInfo) ? _session.sessionInfo.partnerId : null,
        subscriberId: _widgetId,
        widgetType: 'Subscriber',
      }, options);

      if (variation === 'Failure' ||
        (variation === 'iceconnectionstatechange' && payload === 'closed')) {
        stats = assign(stats, _preDisconnectStats);
      }

      const args = [stats];
      if (throttle) { args.push(throttle); }
      analytics.logEvent(...args);
    };

    const connectivityState = createConnectivityState({
      onInvalidTransition(transition, from) {
        const err = `Invalid state transition: Event '${transition}' not possible in state '${from}'`;
        logging.error(err);
        logAnalyticsEvent('Subscriber:InvalidStateTransition', 'Event', {
          details: err,
        });
      },
    });

    {
      const errMessage = 'Unable to subscribe to stream in a reasonable amount of time';
      // make sure we trigger an error if we are not getting any "data" after a reasonable
      // amount of time

      overallTimeout({
        connectivityState,
        onWarning() {
          logConnectivityEvent('Warning', {});
        },
        onTimeout: () => {
          if (_widgetView) {
            _widgetView.addError(errMessage);
          }

          if (_state.isAttemptingToSubscribe()) {
            _state.set('Failed');
            this._disconnect({ noStateTransition: true });
            const error = otError(Errors.TIMEOUT, new Error(errMessage), ExceptionCodes.UNABLE_TO_SUBSCRIBE);
            connectivityState.fail({
              options: {
                failureReason: 'Subscribe',
                failureMessage: errMessage,
                failureCode: ExceptionCodes.UNABLE_TO_SUBSCRIBE,
              },
              error,
            });
          }
        },
        warningMs: BIND_VIDEO_DELAY_WARNING,
        timeoutMs: BIND_VIDEO_DELAY_MAX,
      });
    }

    _pcConnected.promise = new Promise((resolve, reject) => {
      _pcConnected.resolve = resolve;
      _pcConnected.reject = reject;
    });

    if (_properties.testNetwork && _session.sessionInfo.p2pEnabled) {
      logging.warn('You cannot test your network with a relayed session. Use a routed session.');
    }

    this.id = _domId;
    this.widgetId = _widgetId;
    this.session = _session;
    this.stream = _properties.stream;
    _stream = _properties.stream;
    this.streamId = _stream.id;

    if (!_session) {
      OTErrorClass.handleJsException({
        errorMsg: 'OT.Subscriber must be passed a session option',
        code: 2000,
        target: this,
        analytics,
      });

      return null;
    }

    eventing(this);
    _subscriber.once('subscribeComplete', (...args) => {
      try {
        completionHandler(...args);
      } catch (err) {
        console.error('Completion handler threw an exception', err);
      }
    });

    const getVariationFromState = ({ transition, from }) => {
      if (transition === 'fail') {
        if (from === 'connected') {
          return 'Disconnect';
        }
        return 'Failure';
      }
      if (transition === 'disconnect' && from === 'connected') {
        return 'Disconnect';
      }
      return 'Cancel';
    };

    connectivityState.observe({
      onEnterConnecting() {
        logConnectivityEvent('Attempt', null, {});
      },
      onEnterConnected() {
        logConnectivityEvent('Success', null, {});
      },
      onEnterDisconnected(state, { options = {}, payload = null } = {}) {
        logConnectivityEvent(
          getVariationFromState(state),
          payload,
          options
        );
      },
    });

    connectivityState.observe({
      onEnterConnected: () => {
        this.trigger('subscribeComplete', undefined, this);
      },
      onEnterDisconnected: ({ from }, { error } = {}) => {
        if (from === 'connecting') {
          this.trigger('subscribeComplete', error || new Error('An unknown error occurred'), this);
        }
      },
    });

    createConnectivityAttemptPinger({
      connectivityState,
      logAttempt() {
        logAnalyticsEvent('Subscribe', 'Attempting', null, {
          connectionId: _session && _session.isConnected() ?
            _session.connection.connectionId : null,
        });
      },
    });

    const logConnectivityEvent = (variation, payload, options) => {
      if (variation === 'Attempt') {
        _attemptStartTime = new Date().getTime();
      }

      if (variation === 'Failure' || variation === 'Success' || variation === 'Cancel') {
        logAnalyticsEvent('Subscribe', variation, payload, {
          ...options,
          attemptDuration: new Date().getTime() - _attemptStartTime,
        });
      } else {
        logAnalyticsEvent('Subscribe', variation, payload, options);
      }
    };

    const logResubscribe = (variation, payload) => {
      logAnalyticsEvent('ICERestart', variation, payload);
    };

    const recordQOS = ({ parsedStats, remoteConnectionId, peerPriority, peerId }) => {
      const QoSBlob = {
        widgetType: 'Subscriber',
        width: _widgetView.width,
        height: _widgetView.height,
        audioTrack: _webRTCStream && _webRTCStream.getAudioTracks().length > 0,
        hasAudio: _stream && _stream.hasAudio,
        subscribeToAudio: _isSubscribingToAudio,
        audioVolume: this.getAudioVolume(),
        videoTrack: _webRTCStream && _webRTCStream.getVideoTracks().length > 0,
        connectionId: _session ? _session.connection.connectionId : null,
        hasVideo: _stream && _stream.hasVideo,
        subscribeToVideo: _properties.subscribeToVideo,
        congestionLevel: _congestionLevel,
        streamId: _stream.id,
        subscriberId: _widgetId,
        duration: Math.round((now() - _subscribeStartTime) / 1000),
        remoteConnectionId,
        peerPriority,
        peerId,
      };
      const combinedStats = assign(QoSBlob, parsedStats);
      analytics.logQOS(combinedStats);
      this.trigger('qos', analytics.combineWithCommon(combinedStats));
    };

    const stateChangeFailed = (changeFailed) => {
      logging.error('OT.Subscriber State Change Failed: ', changeFailed.message);
      logging.debug(changeFailed);
    };

    const onLoaded = () => {
      if (_state.isSubscribing() || !_widgetView || !_widgetView.video()) {
        return;
      }

      _loaded = true;

      if (!_hasLoadedAtLeastOnce) {
        connectivityState.connect();
        _hasLoadedAtLeastOnce = true;
      }

      logging.debug('OT.Subscriber.onLoaded');

      _state.set('Subscribing');
      _subscribeStartTime = now();

      _widgetView.loading(false);
      if (_chrome) {
        _chrome.showAfterLoading();
      }

      if (_frameRateRestricted) {
        _stream.setRestrictFrameRate(true);
      }

      if (_audioLevelMeter) {
        _audioLevelMeter.audioOnly(_widgetView.audioOnly());
      }

      this.setAudioVolume(_audioVolume);
      this.trigger('loaded', this);
    };

    const onPeerDisconnected = () => {
      logging.debug('OT.Subscriber has been disconnected from the Publisher\'s PeerConnection');

      connectivityState.fail({
        options: {
          failureReason: 'PeerConnectionError',
          failureCode: ExceptionCodes.UNABLE_TO_PUBLISH,
          failureMessage: 'PeerConnection disconnected',
        },
        error: otError(
          errors.DISCONNECTED,
          new Error('ClientDisconnected')
        ),
      });

      this._disconnect({ noStateTransition: true });

      if (_state.isAttemptingToSubscribe() || _state.isSubscribing()) {
        _state.set('Failed');
      }
    };

    const onVideoError = (plainErr) => {
      const err = otError(errors.MEDIA_ERR_DECODE, plainErr, plainErr.code || ExceptionCodes.P2P_CONNECTION_FAILED);
      err.message = `OT.Subscriber while playing stream: ${err.message}`;

      logging.error('OT.Subscriber.onVideoError');
      connectivityState.fail({
        options: {
          failureReason: 'VideoElement',
          failureMessage: err.message,
          failureCode: err.code || ExceptionCodes.P2P_CONNECTION_FAILED,
        },
        error: err,
      });

      const isAttemptingToSubscribe = _state.isAttemptingToSubscribe();

      _state.set('Failed');

      if (!isAttemptingToSubscribe) {
        // FIXME: This emits a string instead of an error here for backwards compatibility despite
        // being undocumented. When possible we should remove access to this and other undocumented
        // events, and restore emitting actual errors here.
        _subscriber.trigger('error', err.message);
      }

      OTErrorClass.handleJsException({
        error: err,
        code: ExceptionCodes.UNABLE_TO_SUBSCRIBE, // @todo why do we override the code?
        target: _subscriber,
        analytics,
      });
    };

    const onSubscriberCreateError = (rawError) => {
      // @todo v3 These errors used to come from the peer connection hence the mention
      // of peer connection in the logs/ errors. However they actually have nothing to do
      // with the peer connection. I have chosen to keep the messages / errors the same for
      // now, but we should consider changing them
      const err = interpretSubscriberCreateError(rawError);

      OTErrorClass.handleJsException({
        error: err,
        code: err.code,
        target: _subscriber,
        analytics,
      });

      this._disconnect({ noStateTransition: true });

      const options = {
        failureReason: 'Subscribe',
        failureMessage: `OT.Subscriber PeerConnection Error: ${err.message}`,
        failureCode: ExceptionCodes.P2P_CONNECTION_FAILED,
      };

      logAnalyticsEvent('createPeerConnection', 'Failure', {}, options);
      _showError(err.code);

      connectivityState.fail({
        options,
        error: otError(
          err.name,
          new Error('Subscribe: Subscriber PeerConnection with connection (not found) ' +
            `failed: ${err.message}`),
          err.code
        ),
      });

      if (err.name === Errors.STREAM_NOT_FOUND) {
        // Usually, the subscriber should be kept in an error state when the peer connection fails
        // because it still exists in the session. But when we get a 404 that means it doesn't
        // exist, even if the session still thinks it does.
        this._destroy({ reason: 'streamNotFound', noStateTransition: true });
      }
    };

    const onPeerConnectionFailure = (code, reason, peerConnection, prefix) => {
      if (
        prefix === 'SetRemoteDescription' &&
        !_isVideoSupported &&
        reason.match(/Unsupported video without audio for fallback/)
      ) {
        if (_widgetView) {
          _widgetView.addError(
            'The stream is unable to be played.',
            'Your browser does not support the video format.'
          );
        }

        let err;
        if (OTHelpers.env.name === 'Safari') {
          err = new Error(
            'VP8 is not supported in this version of Safari. You might want to consider switching to ' +
            'an H264 project. See https://tokbox.com/safari for more information.'
          );
        } else {
          err = new Error('Video format not supported in this browser.');
        }
        err.code = ExceptionCodes.UNSUPPORTED_VIDEO_CODEC;
        onVideoError(err);
        return;
      }

      if (prefix === 'ICEWorkflow' && _session.sessionInfo.reconnection && _loaded) {
        logging.debug('Ignoring peer connection failure due to possibility of reconnection.');
        return;
      }

      let errorCode;
      if (prefix === 'ICEWorkflow') {
        errorCode = ExceptionCodes.SUBSCRIBER_ICE_WORKFLOW_FAILED;
      } else if (code === ExceptionCodes.STREAM_LIMIT_EXCEEDED) {
        errorCode = code;
      } else {
        errorCode = ExceptionCodes.P2P_CONNECTION_FAILED;
      }
      const options = {
        failureReason: prefix || 'PeerConnectionError',
        failureMessage: `OT.Subscriber PeerConnection Error: ${reason}`,
        failureCode: errorCode,
      };

      const error = interpretPeerConnectionError(
        code,
        reason,
        prefix,
        '(not found)',
        'Subscriber'
      );

      connectivityState.fail({ options, error });

      if (_state.isAttemptingToSubscribe()) {
        // We weren't subscribing yet so this was a failure in setting
        // up the PeerConnection or receiving the initial stream.
        const payload = {
          hasRelayCandidates: peerConnection && peerConnection.hasRelayCandidates(),
        };
        logAnalyticsEvent('createPeerConnection', 'Failure', payload, options);

        _state.set('Failed');
      } else if (_state.isSubscribing()) {
        // we were disconnected after we were already subscribing
        _state.set('Failed');
        this.trigger('error', reason);
      }

      this._disconnect({ noStateTransition: true });

      if (Number(code) === 404) {
        // Usually, the subscriber should be kept in an error state when the peer connection fails
        // because it still exists in the session. But when we get a 404 that means it doesn't
        // exist, even if the session still thinks it does.
        this._destroy({ noStateTransition: true });
      }

      OTErrorClass.handleJsException({
        errorMsg: `OT.Subscriber PeerConnection Error: ${reason}`,
        errorCode,
        target: this,
        analytics,
      });
      _showError(code);
    };

    const onRemoteStreamAdded = async (webRTCStream) => {
      _webRTCStream = webRTCStream;

      logging.debug('OT.Subscriber.onRemoteStreamAdded');

      try {
        await waitUntil(() => hasExpectedTracks(_webRTCStream, _stream));
      } catch (err) {
        if (err.message === 'TIMEOUT') {
          logging.error('The expected tracks never arrived');
        } else {
          throw err;
        }
      }

      _state.set('BindingRemoteStream');

      // Disable the audio/video, if needed
      this.subscribeToAudio(_isSubscribingToAudio, false);

      _lastSubscribeToVideoReason = 'loading';
      this.subscribeToVideo(_properties.subscribeToVideo, 'loading');

      // setting resolution and frame rate doesn't work in P2P
      if (!_session.sessionInfo.p2pEnabled) {
        this.setPreferredResolution(_properties.preferredResolution);
        this.setPreferredFrameRate(_properties.preferredFrameRate);
      }

      const videoContainerOptions = {
        error: onVideoError,
        audioVolume: _audioVolume,
      };

      // This is a workaround for a bug in Chrome where a track disabled on
      // the remote end doesn't fire loadedmetadata causing the subscriber to timeout
      // https://jira.tokbox.com/browse/OPENTOK-15605
      // Also https://jira.tokbox.com/browse/OPENTOK-16425
      // Also https://tokbox.atlassian.net/browse/OPENTOK-27112
      webRTCStream.getVideoTracks().forEach((track) => {
        if (global.webkitMediaStream) {
          track.enabled = false;
        } else {
          track.enabled = _stream.hasVideo && _properties.subscribeToVideo;
        }
      });

      try {
        await _widgetView.bindVideo(webRTCStream, videoContainerOptions);
      } catch (err) {
        if (err instanceof CancellationError) {
          // don't do anything if the bindVideo was cancelled...
          return;
        }
        onVideoError(err);
        throw err;
      } finally {
        _widgetView.loading(false);
      }

      const currentPc = getPriorityPeerConnection();
      getAllPeerConnections()
        .filter(x => x !== currentPc)
        .forEach(async (futurePc) => {
          removePeerConnectionByPromise(futurePc);
          const pc = await futurePc;
          logging.debug('closing old peer connection');
          pc.close();
        });

      if (_state.isDestroyed()) {
        throw new Error('Subscriber destroyed');
      }

      if (global.webkitMediaStream) {
        // Enable any video streams that we previously disabled for OPENTOK-27112
        webRTCStream.getVideoTracks().forEach((track) => {
          track.enabled = _stream.hasVideo && _properties.subscribeToVideo;
        });
      }

      const video = _widgetView && _widgetView.video();
      if (video) {
        video.orientation({
          width: _stream.videoDimensions.width,
          height: _stream.videoDimensions.height,
          videoOrientation: _stream.videoDimensions.orientation,
        });
      }

      const videoIndicator = _chrome && _chrome.videoUnsupportedIndicator;
      if (videoIndicator) {
        videoIndicator.setVideoUnsupported(!_isVideoSupported);
      }

      const videoElementCreated = new Promise((resolve, reject) => {
        const video = _widgetView && _widgetView.video();
        if (video && video.domElement()) {
          resolve();
          return;
        }

        _widgetView.once('videoElementCreated', resolve);
        _subscriber.once('destroyed', reject);
      });

      await videoElementCreated;
      await _pcConnected.promise;

      onLoaded();

      // if the audioLevelSampler implementation requires a stream we need to set it now
      if (_audioLevelSampler && 'webRTCStream' in _audioLevelSampler
        && webRTCStream.getAudioTracks().length > 0) {
        _audioLevelSampler.webRTCStream(webRTCStream);
      }

      // We only need frame rate watcher in Safari because framesPerSecond is
      // always zero so we need to rely on calculating the difference of
      // framesDecoded across multiple getStats invocations.
      // See https://bugs.webkit.org/show_bug.cgi?id=172682

      if (OTHelpers.env.name === 'Safari' || OTHelpers.env.isChromiumEdge) {
        if (_frameRateWatcher) {
          _frameRateWatcher.destroy();
          _frameRateWatcher = null;
        }
        const peerConnection = await getPriorityPeerConnection();
        _frameRateWatcher = watchFrameRate(peerConnection.getStats.bind(peerConnection));
      }

      this.trigger('streamAdded', this);
    };

    const onRemoteStreamRemoved = (webRTCStream) => {
      _webRTCStream = null;
      logging.debug('OT.Subscriber.onStreamRemoved');

      const video = _widgetView && _widgetView.video();
      if (video && video.stream === webRTCStream) {
        _widgetView.destroyVideo();
      }

      this.trigger('streamRemoved', this);
    };

    const onRemoteVideoSupported = (supported) => {
      // as _isVideoSupported is true by default, this will only ever be hit if we receive
      // a onRemoteVideoSupported false, and then a onRemoteVideoSupported true. In other words
      // it will not trigger an event if video is enabled and is never disabled due to codec issues.

      if (_isVideoSupported !== supported) {
        this.dispatchEvent(new Events.VideoEnabledChangedEvent(
          supported ? 'videoEnabled' : 'videoDisabled',
          {
            reason: supported ? 'codecChanged' : 'codecNotSupported',
          }
        ));
      }
      _isVideoSupported = supported;
    };

    const audioBlockedStateChange = (value) => {
      _widgetView.setAudioBlockedUi(value);

      const indicator = _chrome && _chrome.audioBlockedIndicator;

      if (indicator) {
        indicator.setAudioBlocked(value);
      }

      const videoIndicator = _chrome && _chrome.videoUnsupportedIndicator;

      if (videoIndicator) {
        videoIndicator.setVideoUnsupported(
          !value &&
          !_isVideoSupported
        );
      }
    };

    this.on('audioBlocked', () => { audioBlockedStateChange(true); });
    this.on('audioUnblocked', () => { audioBlockedStateChange(false); });

    const connectionStateMap = {
      new: eventNames.SUBSCRIBER_DISCONNECTED,
      checking: eventNames.SUBSCRIBER_DISCONNECTED,
      connected: eventNames.SUBSCRIBER_CONNECTED,
      completed: eventNames.SUBSCRIBER_CONNECTED,
      disconnected: eventNames.SUBSCRIBER_DISCONNECTED,
    };

    const onIceConnectionStateChange = (state) => {
      const mappedState = connectionStateMap[state];
      if (mappedState && mappedState !== _lastIceConnectionState) {
        _lastIceConnectionState = mappedState;

        if (_widgetView) {
          _widgetView.loading(mappedState !== eventNames.SUBSCRIBER_CONNECTED);
        }

        logging.debug(`OT.Subscriber.connectionStateChanged to ${state}`);
        this.dispatchEvent(new Events.ConnectionStateChangedEvent(mappedState, this));
      }
    };

    const onIceRestartSuccess = () => {
      logResubscribe('Success');
    };

    const onIceRestartFailure = () => {
      logResubscribe('Failure', {
        reason: 'ICEWorkflow',
        message: 'OT.Subscriber PeerConnection Error: ' +
          'The stream was unable to connect due to a network error.' +
          ' Make sure your connection isn\'t blocked by a firewall.',
      });
    };

    const streamUpdated = (event) => {
      const video = _widgetView && _widgetView.video();
      switch (event.changedProperty) {
        case 'videoDimensions':
          if (!video) {
            // Ignore videoDimensions updates before video is created OPENTOK-17253
            break;
          }
          video.orientation({
            width: event.newValue.width,
            height: event.newValue.height,
            videoOrientation: event.newValue.orientation,
          });

          this.dispatchEvent(new Events.VideoDimensionsChangedEvent(
            this, event.oldValue, event.newValue
          ));

          break;

        case 'videoDisableWarning':
          if (_chrome) {
            _chrome.videoDisabledIndicator.setWarning(event.newValue);
          }
          this.dispatchEvent(new Events.VideoDisableWarningEvent(
            event.newValue ? 'videoDisableWarning' : 'videoDisableWarningLifted'
          ));
          _congestionLevel = event.newValue === 'videoDisableWarning' ? 1 : null;
          break;

        case 'hasVideo':
          // @todo setAudioOnly affects peer connections, what happens with new ones?
          setAudioOnly(!_stream.hasVideo || !_properties.subscribeToVideo);

          this.dispatchEvent(new Events.VideoEnabledChangedEvent(
            _stream.hasVideo ? 'videoEnabled' : 'videoDisabled',
            { reason: 'publishVideo' }
          ));
          break;

        case 'hasAudio':
          _muteDisplayMode.update();
          break;

        default:
      }
    };

    // Use _stream.getChannelsOfType instead of _webRTCStream.getAudioTracks
    // because its available as soon as Subscriber is instantiated.
    const _hasAudioTracks = () => _stream.getChannelsOfType('audio').length > 0;

    // / Chrome

    _muteDisplayMode = {
      get() {
        // Use buttonDisplayMode if we have an audio track, even if its muted
        return _hasAudioTracks() ? _subscriber.getStyle('buttonDisplayMode') : 'off';
      },
      update() {
        const mode = _muteDisplayMode.get();
        if (_chrome) {
          _chrome.muteButton.setDisplayMode(mode);
          _chrome.backingBar.setMuteMode(mode);
        }
      },
    };

    const updateChromeForStyleChange = (key, value/* , oldValue */) => {
      if (!_chrome) { return; }

      switch (key) {
        case 'nameDisplayMode':
          _chrome.name.setDisplayMode(value);
          _chrome.backingBar.setNameMode(value);
          break;

        case 'videoUnsupportedDisplayMode':
          _chrome.videoUnsupportedIndicator.setDisplayMode(value);
          break;

        case 'videoDisabledDisplayMode':
          _chrome.videoDisabledIndicator.setDisplayMode(value);
          break;

        case 'audioBlockedDisplayMode':
          _chrome.audioBlockedIndicator.setDisplayMode(value);
          break;

        case 'showArchiveStatus':
          _chrome.archive.setShowArchiveStatus(value);
          break;

        case 'buttonDisplayMode':
          _muteDisplayMode.update();
          break;

        case 'audioLevelDisplayMode':
          _chrome.audioLevel.setDisplayMode(value);
          break;

        case 'bugDisplayMode':
          // bugDisplayMode can't be updated but is used by some partners
          break;

        case 'backgroundImageURI':
          _widgetView.setBackgroundImageURI(value);
          break;

        default:
      }
    };

    const _createChrome = () => {
      const widgets = {
        backingBar: new BackingBar({
          nameMode: !_properties.name ? 'off' : this.getStyle('nameDisplayMode'),
          muteMode: _muteDisplayMode.get(),
        }),

        name: new NamePanel({
          name: _properties.name,
          mode: this.getStyle('nameDisplayMode'),
        }),

        muteButton: new MuteButton({
          muted: _audioVolume === 0,
          mode: _muteDisplayMode.get(),
        }),
      };

      if (_audioLevelCapable) {
        const audioLevelTransformer = new AudioLevelTransformer();

        _audioLevelMeter = new AudioLevelMeter({
          mode: _subscriber.getStyle('audioLevelDisplayMode'),
        });

        const updateAudioLevel = new RafRunner(() => {
          _audioLevelMeter.setValue(audioLevelTransformer.transform(this.loudness));
        });

        _audioLevelMeter.watchVisibilityChanged((visible) => {
          if (visible) {
            updateAudioLevel.start();
          } else {
            updateAudioLevel.stop();
          }
        });

        _audioLevelMeter.setDisplayMode(this.getStyle('audioLevelDisplayMode'));
        _audioLevelMeter.audioOnly(false);

        widgets.audioLevel = _audioLevelMeter;
      }

      widgets.videoDisabledIndicator = new VideoDisabledIndicator({
        mode: this.getStyle('videoDisabledDisplayMode'),
      });

      widgets.audioBlockedIndicator = new AudioBlockedIndicator({
        mode: this.getStyle('audioBlockedDisplayMode'),
      });

      widgets.videoUnsupportedIndicator = new VideoUnsupportedIndicator({
        mode: this.getStyle('videoUnsupportedDisplayMode'),
      });

      if (_widgetView && _widgetView.domElement) {
        _chrome = new Chrome({
          parent: _widgetView.domElement,
        }).set(widgets).on({
          muted() {
            _subscriber.setAudioVolume(0);
          },
          unmuted() {
            _subscriber.setAudioVolume(_latestPositiveVolume);
          },
        }, this);
        // Hide the chrome until we explicitly show it
        _chrome.hideWhileLoading();
      }
    };

    const _showError = (code) => {
      let errorMessage;
      let helpMessage;

      // Display the error message inside the container, assuming it's
      // been created by now.
      if (_widgetView) {
        if (code === ExceptionCodes.STREAM_LIMIT_EXCEEDED) {
          errorMessage = 'The stream was unable to connect.';
          helpMessage = 'The limit for the number of media streams has been reached.';
        } else {
          errorMessage = 'The stream was unable to connect due to a network error.';
          if (_hasLoadedAtLeastOnce) {
            helpMessage = 'Ensure you have a stable connection and try again.';
          } else {
            helpMessage = 'Make sure you have a stable network connection and that it isn\'t ' +
              'blocked by a firewall.';
          }
        }

        _widgetView.addError(errorMessage, helpMessage);
      }
    };

    StylableComponent(this, {
      nameDisplayMode: 'auto',
      buttonDisplayMode: 'auto',
      audioLevelDisplayMode: 'auto',
      videoDisabledDisplayMode: 'auto',
      audioBlockedDisplayMode: 'auto',
      backgroundImageURI: null,
      showArchiveStatus: true,
      showMicButton: true,
    }, _properties.showControls, (payload) => {
      logAnalyticsEvent('SetStyle', 'Subscriber', payload, null, 0.1);
    });

    function setAudioOnly(audioOnly) {
      getAllPeerConnections().forEach(async (peerConnection) => {
        (await peerConnection).subscribeToVideo(!audioOnly);
      });

      if (_widgetView) {
        _widgetView.audioOnly(audioOnly);
        _widgetView.showPoster(audioOnly);
      }

      if (_audioLevelMeter) {
        _audioLevelMeter.audioOnly(audioOnly);
      }
    }

    // logs an analytics event for getStats on the first call
    const notifyGetStatsCalled = once(() => {
      logAnalyticsEvent('GetStats', 'Called');
    });

    this._destroy = ({ reason = 'Unknown', quiet, noStateTransition = false }) => {
      if (_state.isDestroyed()) { return this; }

      _state.set('Destroyed');

      if (_frameRateWatcher) {
        _frameRateWatcher.destroy();
        _frameRateWatcher = null;
      }

      _preDisconnectStats = {
        sessionId: _session.sessionId,
        connectionId: (_session && _session.isConnected()) ? _session.connection.connectionId : null,
        partnerId: (_session && _session.sessionInfo) ? _session.sessionInfo.partnerId : null,
        streamId: (_stream && !_stream.destroyed) ? _stream.id : null,
      };

      this._disconnect({ reason, noStateTransition });

      if (_chrome) {
        _chrome.destroy();
        _chrome = null;
      }

      if (_widgetView) {
        _widgetView.destroy();
        _widgetView.off();
        _widgetView = null;
        this.element = null;
      }

      if (_stream && !_stream.destroyed) {
        logAnalyticsEvent('unsubscribe', null, { streamId: _stream.id });
      }

      _stream.off(_streamEventHandlers, this);

      this.id = null;
      _domId = null;
      this.stream = null;
      _stream = null;
      this.streamId = null;

      this.session = null;
      _session = null;
      _properties = null;

      if (quiet !== true) {
        this.dispatchEvent(new Events.DestroyedEvent(
          eventNames.SUBSCRIBER_DESTROYED,
          this,
          reason
        ));
        this.off();
      }

      return this;
    };

    this.destroy = (reason = 'Unsubscribe', quiet) => {
      logging.warn('Subscriber#destroy is deprecated and will be removed. Please use Session#unsubscribe instead');
      this._destroy({ reason, quiet });
    };

    this._disconnect = ({ reason = 'Unknown', noStateTransition } = {}) => {
      // known reasons:
      // forceUnpublished (publisher stream was destroyed by forceUnpublish)
      // clientDisconnected (publisher unpublished)
      // Unsubscribe (when calling session.unsubscribe)
      // Unknown (when reason is not determined)

      if (!noStateTransition) {
        const error = reason === 'Unsubscribe' ? undefined : otError(
          errors.STREAM_DESTROYED,
          new Error('Stream was destroyed before it could be subscribed to')
        );

        connectivityState.disconnect({ payload: { reason }, error });
      }

      if (!_state.isDestroyed() && !_state.isFailed()) {
        // If we are already in the destroyed state then disconnect
        // has been called after (or from within) destroy.
        _state.set('NotSubscribing');
      }

      if (_widgetView) {
        _widgetView.destroyVideo();
      }

      // @todo check peer connection destroy triggers disconnect, and then gets logged...
      getAllPeerConnections().forEach(async (peerConnection) => { (await peerConnection).destroy(); });
      Object.keys(peerConnections).forEach((key) => { delete peerConnections[key]; });

      // Unsubscribe us from the stream, if it hasn't already been destroyed
      if (socket.is('connected') && _stream && !_stream.destroyed) {
        // Notify the server components
        // @todo I assume we don't want to send this message, but is there anything
        // we need to do in p2p->mantis for when a peer conn is destroyed?
        socket.subscriberDestroy(_stream.id, this.widgetId);
      }
    };

    this.disconnect = () => {
      logging.warn('Subscriber#disconnect is deprecated and will be removed. Please use Session#unsubscribe instead');
      this._disconnect({ reason: 'Unsubscribe' });
    };

    const processOffer = async ({ peerPriority, peerId, fromConnectionId }) => {
      if (!getPeerConnectionByPriority(peerPriority)) {
        logging.info(`PeerConnection escalation to ${peerPriority}`);

        const uri = constructSubscriberUri({
          apiKey: _session.apiKey,
          sessionId: _session.sessionId,
          streamId: _stream.id,
          subscriberId: this.widgetId,
        });

        const send = createSendMethod({
          socket: this.session._.getSocket(),
          uri,
          content: { peerPriority, peerId },
        });

        const log = (action, variation, payload, options = {}, throttle) => {
          const transformedOptions = { peerId, peerPriority, ...options };
          return logAnalyticsEvent(action, variation, payload, transformedOptions, throttle);
        };

        const logQoS = qos =>
          recordQOS({ ...qos, peerId, peerPriority, remoteConnectionId: fromConnectionId });

        await this._setupPeerConnection({ peerPriority, send, log, logQoS });
      }
    };

    this.processMessage = async (type, fromConnectionId, message) => {
      logging.debug(`OT.Subscriber.processMessage: Received ${type} message from ${fromConnectionId}`);
      logging.debug(message);

      const peerPriority = Number(get(message, 'content.peerPriority', 0));
      const peerId = get(message, 'content.peerId');

      if (peerPriority < getMaxPriority()) {
        logging.debug(`Ignoring ${type} message from ${fromConnectionId} with ${peerPriority}. PeerConnection is marked as obsolete`);
        return;
      }

      if (type === 'offer') {
        await processOffer({ peerPriority, peerId, fromConnectionId });
      }

      const peerConnection = await getPeerConnectionByPriority(peerPriority);
      peerConnection.processMessage(type, message);
    };

    this.disableVideo = (active) => {
      if (!active) {
        logging.warn('Due to high packet loss and low bandwidth, video has been disabled');
      } else if (_lastSubscribeToVideoReason === 'auto') {
        logging.info('Video has been re-enabled');
      } else {
        logging.info('Video was not re-enabled because it was manually disabled');
        return;
      }

      this.subscribeToVideo(active, 'auto');
      const payload = active ? { videoEnabled: true } : { videoDisabled: true };
      logAnalyticsEvent('updateQuality', 'video', payload);
    };

    /**
     * Returns the base-64-encoded string of PNG data representing the Subscriber video.
     *
     *  <p>You can use the string as the value for a data URL scheme passed to the src parameter of
     *  an image file, as in the following:</p>
     *
     *  <pre>
     *  var imgData = subscriber.getImgData();
     *
     *  var img = document.createElement("img");
     *  img.setAttribute("src", "data:image/png;base64," + imgData);
     *  var imgWin = window.open("about:blank", "Screenshot");
     *  imgWin.document.write("&lt;body&gt;&lt;/body&gt;");
     *  imgWin.document.body.appendChild(img);
     *  </pre>
     * @method #getImgData
     * @memberOf Subscriber
     * @return {String} The base-64 encoded string. Returns an empty string if there is no video.
     */
    this.getImgData = () => {
      if (!this.isSubscribing()) {
        logging.error('OT.Subscriber.getImgData: Cannot getImgData before the Subscriber ' +
          'is subscribing.');
        return null;
      }

      const video = _widgetView && _widgetView.video();
      return video ? video.imgData() : null;
    };

    /**
    *  Returns the details on the subscriber stream quality, including the following:
    *
    * <ul>
    *
    *   <li>Total audio and video packets lost</li>
    *   <li>Total audio and video packets received</li>
    *   <li>Total audio and video bytes received</li>
    *   <li>Current video frame rate</li>
    *
    * </ul>
    *
    * You can publish a test stream, subscribe to it (on the publishing client), and use this method
    * to check its quality. Based on the stream's quality, you can determine what video resolution is
    * supported and whether conditions support video or audio. You can then publish an appropriate
    * stream, based on the results. When using this method to test a stream published by your
    * own client, set the <code>testNetwork</code> property to <code>true</code> in the options you
    * pass into the <a href="Session.html#subscribe">Session.subscribe()</a> method. For an example,
    * see the <a href="https://github.com/opentok/opentok-network-test">opentok-network-test</a>
    * project on GitHub.
    * <p>
    * You may also use these statistics to have a Subscriber subscribe to audio-only if the audio
    * packet loss reaches a certain threshold. If you choose to do this, you should set the
    * <code>audioFallbackEnabled</code> setting to <code>false</code> when you initialize Publisher
    * objects for the session. This prevents the OpenTok Media Router from using its own audio-only
    * toggling implementation. (See the documentation for the
    * <a href="OT.html#initPublisher">OT.initPublisher()</a> method.)
    *
    * @param {Function} completionHandler A function that takes the following
    * parameters:
    *
    * <ul>
    *
    *   <li><code>error</code> (<a href="Error.html">Error</a>) &mdash; The error property is
    *   set if the client is not connected. Upon completion of a successful call to the method,
    *   this property is undefined.</li>
    *
    *   <li><code>stats</code> (Object) &mdash; An object with the following properties:
    *     <p>
    *     <ul>
    *       <li><code>audio.bytesReceived</code> (Number) &mdash; The total number of audio bytes
    *         received by the subscriber</li>
    *       <li><code>audio.packetsLost</code> (Number) &mdash; Total audio packets that did not reach
    *         the subscriber</li>
    *       <li><code>audio.packetsReceived</code> (Number) &mdash; The total number of audio packets
    *         received by the subscriber</li>
    *       <li><code>timestamp</code> (Number) &mdash; The timestamp, in milliseconds since the Unix
    *         epoch, for when these stats were gathered</li>
    *       <li><code>video.bytesReceived</code> (Number) &mdash; The total video bytes received by
    *         the subscriber</li>
    *       <li><code>video.packetsLost</code> (Number) &mdash; The total number of video packets that
    *         did not reach the subscriber</li>
    *       <li><code>video.packetsReceived</code> (Number) &mdash; The total number of video packets
    *         received by the subscriber</li>
    *       <li><code>video.frameRate</code> (Number) &mdash; The current video frame rate</li>
    *     </ul>
    *   </li>
    * </ul>
    *
    * @see <a href="Publisher.html#getStats">Publisher.getStats()</a>
    *
    * @method #getStats
    * @memberOf Subscriber
    */
    this.getStats = async (callback) => {
      if (!getPriorityPeerConnection()) {
        callback(otError(
          errors.NOT_CONNECTED,
          new Error('OT.Subscriber is not connected cannot getStats'),
          1015
        ));
        return;
      }

      notifyGetStatsCalled();

      const pc = await getPriorityPeerConnection();

      pc.getStats((error, stats) => {
        if (error) {
          if (error.code === 1015) {
            error = otError(
              errors.NOT_CONNECTED,
              error,
              1015
            );
          }
          callback(error);
          return;
        }

        const otStats = {
          timestamp: 0,
        };

        stats.forEach((stat) => {
          if (getStatsHelpers.isInboundStat(stat)) {
            const video = getStatsHelpers.isVideoStat(stat, stats);
            const audio = getStatsHelpers.isAudioStat(stat, stats);

            // it is safe to override the timestamp of one by another
            // if they are from the same getStats "batch" video and audio ts have the same value
            if (audio || video) {
              otStats.timestamp = getStatsHelpers.normalizeTimestamp(stat.timestamp);
            }
            if (video) {
              merge(otStats, { video: getStatsHelpers.parseStatCategory(stat) });
            } else if (audio) {
              merge(otStats, { audio: getStatsHelpers.parseStatCategory(stat) });
            }
          } else if (getStatsHelpers.isVideoTrackStat(stat)) {
            let { framesPerSecond: frameRate } = stat;
            if (_frameRateWatcher) {
              if (frameRate) {
                _frameRateWatcher.destroy();
                _frameRateWatcher = null;
              } else {
                frameRate = _frameRateWatcher.getFrameRateFromStats(stats);
              }
            }
            merge(otStats, { video: { frameRate } });
          }
        });

        callback(null, otStats);
      });
    };

    function setAudioVolume(audioVolume) {
      const video = _widgetView && _widgetView.video();
      if (video) {
        try {
          video.setAudioVolume(audioVolume);
        } catch (e) {
          logging.warn(`setAudioVolume: ${e}`);
          if (_audioVolume === 0) {
            logging.info('Using subscribeToAudio to mute Audio because setAudioVolume(0) failed');
            // If we can't set the audioVolume to 0 then at least mute by setting subscribeToAudio(false)
            _subscriber.subscribeToAudio(false);
          }
        }
      }

      if (_chrome) {
        _chrome.muteButton.muted(audioVolume === 0);
      }
    }

    /**
     * Sets the audio volume, between 0 and 100, of the Subscriber.
     *
     * <p>You can set the initial volume when you call the <code>Session.subscribe()</code>
     * method. Pass a <code>audioVolume</code> property of the <code>properties</code> parameter
     * of the method.</p>
     *
     * @param {Number} value The audio volume, between 0 and 100.
     *
     * @return {Subscriber} The Subscriber object. This lets you chain method calls, as in the
     * following:
     *
     * <pre>mySubscriber.setAudioVolume(50).setStyle(newStyle);</pre>
     *
     * @see <a href="#getAudioVolume">getAudioVolume()</a>
     * @see <a href="Session.html#subscribe">Session.subscribe()</a>
     * @method #setAudioVolume
     * @memberOf Subscriber
     */

    this.setAudioVolume = (requestedVolume) => {
      const volume = normalizeAudioVolume(requestedVolume);
      logAnalyticsEvent('setAudioVolume', 'Attempt', { audioVolume: volume });
      if (isNaN(volume)) {
        logging.error('OT.Subscriber.setAudioVolume: value should be an integer between 0 and 100');
        logAnalyticsEvent('setAudioVolume', 'Failure', { message: 'value should be an integer between 0 and 100' });
        return this;
      }

      if (volume !== requestedVolume) {
        logging.warn('OT.Subscriber.setAudioVolume: value should be an integer between 0 and 100');
      }

      if (volume === _audioVolume) {
        setAudioVolume(_audioVolume);
        logAnalyticsEvent('setAudioVolume', 'Success', { audioVolume: _audioVolume, message: 'Requested volume is same as already set audioVolume' });
        return this;
      }

      if (_audioVolume > 0) {
        _latestPositiveVolume = _audioVolume;
      }

      _audioVolume = volume;

      setAudioVolume(_audioVolume);

      if (_audioVolume > 0 && !_isSubscribingToAudio) {
        // in Firefox (and others) we don't stop subscribing to audio when muted, however if we are 'unmuting' and in
        // the subscribeToAudio: false state we should subscribe to audio again

        // subscribeToAudio is going to call us with _latestPositiveVolume so we'll update it here
        _latestPositiveVolume = _audioVolume;
        this.subscribeToAudio(true);
      }

      logAnalyticsEvent('setAudioVolume', 'Success', { audioVolume: _audioVolume });
      return this;
    };

    /**
    * Returns the audio volume, between 0 and 100, of the Subscriber.
    *
    * <p>Generally you use this method in conjunction with the <code>setAudioVolume()</code>
    * method.</p>
    *
    * @return {Number} The audio volume, between 0 and 100, of the Subscriber.
    * @see <a href="#setAudioVolume">setAudioVolume()</a>
    * @method #getAudioVolume
    * @memberOf Subscriber
    */
    this.getAudioVolume = () => {
      const video = _widgetView && _widgetView.video();
      if (video) {
        try {
          return video.getAudioVolume();
        } catch (e) {
          logging.warn(`getAudioVolume ${e}`);
        }
      }
      return _audioVolume;
    };

    /**
    * Toggles audio on and off. Starts subscribing to audio (if it is available and currently
    * not being subscribed to) when the <code>value</code> is <code>true</code>; stops
    * subscribing to audio (if it is currently being subscribed to) when the <code>value</code>
    * is <code>false</code>.
    * <p>
    * <i>Note:</i> This method only affects the local playback of audio. It has no impact on the
    * audio for other connections subscribing to the same stream. If the Publisher is not
    * publishing audio, enabling the Subscriber audio will have no practical effect.
    * </p>
    *
    * @param {Boolean} value Whether to start subscribing to audio (<code>true</code>) or not
    * (<code>false</code>).
    *
    * @return {Subscriber} The Subscriber object. This lets you chain method calls, as in the
    * following:
    *
    * <pre>mySubscriber.subscribeToAudio(true).subscribeToVideo(false);</pre>
    *
    * @see <a href="#subscribeToVideo">subscribeToVideo()</a>
    * @see <a href="Session.html#subscribe">Session.subscribe()</a>
    * @see <a href="StreamPropertyChangedEvent.html">StreamPropertyChangedEvent</a>
    *
    * @method #subscribeToAudio
    * @memberOf Subscriber
    */

    this.subscribeToAudio = (pValue) => {
      const value = castToBoolean(pValue, true);

      getAllPeerConnections().forEach(async (peerConnection) => {
        (await peerConnection).subscribeToAudio(value);
      });

      if (_stream && getAllPeerConnections().length !== 0) {
        _stream.setChannelActiveState('audio', value);
      }

      const changed = _isSubscribingToAudio !== value;
      _isSubscribingToAudio = value;

      if (changed) {
        this.setAudioVolume(value ? _latestPositiveVolume : 0);
      }

      logAnalyticsEvent('subscribeToAudio', 'Event', { subscribeToAudio: value });

      return this;
    };

    const reasonMap = {
      auto: 'quality',
      publishVideo: 'publishVideo',
      subscribeToVideo: 'subscribeToVideo',
    };

    if (env.name === 'Safari') {
      const onVisibilityChange = () => {
        if (!document.hidden && _properties.subscribeToVideo) {
          logging.debug('document visibility restored in Safari - resubscribing to video');
          this.subscribeToVideo(false);
          this.subscribeToVideo(true);
        }
      };

      document.addEventListener('visibilitychange', onVisibilityChange);

      this.once('destroyed', () => {
        document.removeEventListener('visibilitychange', onVisibilityChange);
      });
    }

    /**
    * Toggles video on and off. Starts subscribing to video (if it is available and
    * currently not being subscribed to) when the <code>value</code> is <code>true</code>;
    * stops subscribing to video (if it is currently being subscribed to) when the
    * <code>value</code> is <code>false</code>.
    * <p>
    * <i>Note:</i> This method only affects the local playback of video. It has no impact on
    * the video for other connections subscribing to the same stream. If the Publisher is not
    * publishing video, enabling the Subscriber video will have no practical effect.
    * </p>
    *
    * @param {Boolean} value Whether to start subscribing to video (<code>true</code>) or not
    * (<code>false</code>).
    *
    * @return {Subscriber} The Subscriber object. This lets you chain method calls, as in the
    * following:
    *
    * <pre>mySubscriber.subscribeToVideo(true).subscribeToAudio(false);</pre>
    *
    * @see <a href="#subscribeToAudio">subscribeToAudio()</a>
    * @see <a href="Session.html#subscribe">Session.subscribe()</a>
    * @see <a href="StreamPropertyChangedEvent.html">StreamPropertyChangedEvent</a>
    *
    * @method #subscribeToVideo
    * @memberOf Subscriber
    */
    this.subscribeToVideo = (pValue, reason) => {
      const value = castToBoolean(pValue, true);

      logAnalyticsEvent('subscribeToVideo', 'Attempt', { subscribeToVideo: value, reason });

      setAudioOnly(
        !value ||
        !_stream.hasVideo ||
        !(_webRTCStream && _webRTCStream.getVideoTracks().length > 0)
      );

      if (_stream.hasVideo && _webRTCStream && _webRTCStream.getVideoTracks().length === 0) {
        if (value) {
          logging.info('Subscriber is audio-only due to incompatibility, can\'t subscribeToVideo.');
        }

        _properties.subscribeToVideo = false;
        logAnalyticsEvent('subscribeToVideo', 'Failure', { message: 'No video tracks available' });
        return this;
      }

      if (_chrome && _chrome.videoDisabledIndicator) {
        // If this is an auto disableVideo then we want to show the indicator, otherwise hide it again
        _chrome.videoDisabledIndicator.disableVideo(reason === 'auto' && !value);
      }

      if (getAllPeerConnections().length > 0) {
        if (_session && _stream && (value !== _properties.subscribeToVideo ||
          reason !== _lastSubscribeToVideoReason)) {
          _stream.setChannelActiveState('video', value, reason);
        }
      }

      _properties.subscribeToVideo = value;
      _lastSubscribeToVideoReason = reason;

      logAnalyticsEvent('subscribeToVideo', 'Success', { subscribeToVideo: value, reason });

      if (reason !== 'loading') {
        this.dispatchEvent(new Events.VideoEnabledChangedEvent(
          value ? 'videoEnabled' : 'videoDisabled',
          {
            reason: reasonMap[reason] || 'subscribeToVideo',
          }
        ));
        if (value === 'videoDisabled' && reason === 'auto') {
          _congestionLevel = 2;
        }
      }

      return this;
    };

    /**
    * Sets the preferred resolution of the subscriber's video.
    * <p>
    * Lowering the preferred resolution
    * lowers video quality on the subscribing client, but it also reduces network and CPU usage.
    * You may want to use a lower resolution based on the dimensions of subscriber's video on
    * the web page. You may want to use a resolution rate for a subscriber to a stream that is less
    * important (and smaller) than other streams.
    * <p>
    * <p>
    * This method only applies when subscribing to a stream that uses the
    * <a href="https://tokbox.com/developer/guides/scalable-video">
    * scalable video feature</a>. Scalable video is available:
    * <ul>
    *   <li>
    *     Only in sessions that use the OpenTok Media Router (sessions with the
    *     <a href="https://tokbox.com/developer/guides/create-session/#media-mode">media
    *     mode</a> set to routed).
    *   </li>
    *   <li>
    *     Only for streams published by clients that support scalable video:
    *     clients that use the OpenTok iOS SDK (on certain devices), the OpenTok
    *     Android SDK (on certain devices), or OpenTok.js in Chrome and Safari.
    *   </li>
    * </ul>
    * <p>
    * In streams that do not use scalable video, calling this method has no effect.
    * <p>
    * <b>Note:</b> The resolution for scalable video streams automatically adjusts for each
    * subscriber, based on network conditions and CPU usage, even if you do not call this method.
    * Call this method if you want to set a maximum resolution for this subscriber.
    * <p>
    * In streams that do not use scalable video, calling this method has no effect.
    * <p>
    * Not every frame rate is available to a subscriber. When you set the preferred resolution for
    * the subscriber, OpenTok.js picks the best resolution available that matches your setting.
    * The resolutions available are based on the value of the Subscriber object's
    * <code>stream.resolution</code> property, which represents the maximum resolution available for
    * the stream. The actual resolutions available depend, dynamically, on network and CPU resources
    * available to the publisher and subscriber.
    * <p>
    * You can set the initial preferred resolution used by setting the
    * <code>preferredResolution</code> property of the <code>options</code> object you pass into the
    * <code>Session.subscribe()</code> method.
    *
    * @param {Object} resolution Set this to an object with two properties: <code>width</code> and
    * <code>height</code> (both numbers), such as <code>{width: 320, height: 240}</code>. Set this to
    * <code>null</code> to remove the preferred resolution, and the client will use the highest
    * resolution available.
    *
    * @see <a href="#setPreferredFrameRate">Subscriber.setPreferredFrameRate()</a>
    * @see <a href="Session.html#subscribe">Session.subscribe()</a>
    *
    * @method #setPreferredResolution
    * @memberOf Subscriber
    */
    this.setPreferredResolution = (preferredResolution) => {
      if (_state.isDestroyed() || (getAllPeerConnections().length === 0 && !_state.current === 'Connecting')) {
        logging.warn('Cannot set the max Resolution when not subscribing to a publisher');
        return;
      }

      _properties.preferredResolution = preferredResolution;

      if (_session.sessionInfo.p2pEnabled) {
        logging.warn('OT.Subscriber.setPreferredResolution will not work in a P2P Session');
        return;
      }

      const curMaxResolution = _stream.getPreferredResolution();

      const isUnchanged = (curMaxResolution && preferredResolution &&
        curMaxResolution.width === preferredResolution.width &&
        curMaxResolution.height === preferredResolution.height) ||
        (!curMaxResolution && !preferredResolution);

      if (isUnchanged) {
        return;
      }

      _stream.setPreferredResolution(preferredResolution);
    };

    /**
    * Sets the preferred frame rate of the subscriber's video.
    * <p>
    * Lowering the preferred frame rate
    * lowers video quality on the subscribing client, but it also reduces network and CPU usage.
    * You may want to use a lower frame rate for a subscriber to a stream that is less important
    * than other streams.
    * <p>
    * <p>
    * This method only applies when subscribing to a stream that uses the
    * <a href="https://tokbox.com/developer/guides/scalable-video">
    * scalable video feature</a>. Scalable video is available:
    * <ul>
    *   <li>
    *     Only in sessions that use the OpenTok Media Router (sessions with the
    *     <a href="https://tokbox.com/developer/guides/create-session/#media-mode">media
    *     mode</a> set to routed).
    *   </li>
    *   <li>
    *     Only for streams published by clients that support scalable video:
    *     clients that use the OpenTok iOS SDK (on certain devices), the OpenTok
    *     Android SDK (on certain devices), or OpenTok.js in Chrome and Safari.
    *   </li>
    * </ul>
    * <p>
    * In streams that do not use scalable video, calling this method has no effect.
    * <p>
    * <b>Note:</b> The frame rate for scalable video streams automatically adjusts for each
    * subscriber, based on network conditions and CPU usage, even if you do not call this method.
    * Call this method if you want to set a maximum frame rate for this subscriber.
    * <p>
    * Not every frame rate is available to a subscriber. When you set the preferred frame rate for
    * the subscriber, OpenTok.js picks the best frame rate available that matches your setting.
    * The frame rates available are based on the value of the Subscriber object's
    * <code>stream.frameRate</code> property, which represents the maximum value available for the
    * stream. The actual frame rates available depend, dynamically, on network and CPU resources
    * available to the publisher and subscriber.
    * <p>
    * You can set the initial preferred frame rate used by setting the <code>preferredFrameRate</code>
    * property of the <code>options</code> object you pass into the <code>Session.subscribe()</code>
    * method.
    *
    * @param {Number} frameRate Set this to the desired frame rate (in frames per second). Set this to
    * <code>null</code> to remove the preferred frame rate, and the client will use the highest
    * frame rate available.
    *
    * @see <a href="#setPreferredResolution">Subscriber.setPreferredResolution()</a>
    * @see <a href="Session.html#subscribe">Session.subscribe()</a>
    *
    * @method #setPreferredFrameRate
    * @memberOf Subscriber
    */
    this.setPreferredFrameRate = (preferredFrameRate) => {
      if (_state.isDestroyed() || (getAllPeerConnections().length === 0 && !_state.current === 'Connecting')) {
        logging.warn('Cannot set the max frameRate when not subscribing to a publisher');
        return;
      }

      _properties.preferredFrameRate = preferredFrameRate;

      if (_session.sessionInfo.p2pEnabled) {
        logging.warn('OT.Subscriber.setPreferredFrameRate will not work in a P2P Session');
        return;
      }
      const currentPreferredFrameRate = _stream.getPreferredFrameRate();

      const isUnchangedFrameRate = (preferredFrameRate && currentPreferredFrameRate &&
      (currentPreferredFrameRate === preferredFrameRate)) ||
      (!currentPreferredFrameRate && !preferredFrameRate);

      if (isUnchangedFrameRate) {
        return;
      }

      _stream.setPreferredFrameRate(preferredFrameRate);
    };

    this.isSubscribing = () => _state.isSubscribing();

    this.isWebRTC = true;

    this.isLoading = () => _widgetView && _widgetView.loading();

    /**
     * Indicates whether the subscriber's audio is blocked because of
     * the browser's audio autoplay policy.
     *
     * @see <a href="OT.html#unblockAudio">OT.unblockAudio()</a>
     * @see The <a href="#event:audioBlocked">audioBlocked</a>
     * and <a href="#event:audioUnblocked">audioUnblocked</a>
     * Subscriber events
     * @see The <code>style.audioBlockedDisplayMode</code> property of the
     * <code>options</code> parameter of the
     * <a href="Session.html#subscribe">Session.subscribe()</a> method
     *
     * @method #isAudioBlocked
     * @memberof Subscriber
     */
    this.isAudioBlocked = () => Boolean(_widgetView && _widgetView.isAudioBlocked());

    this.videoElement = () => {
      const video = _widgetView && _widgetView.video();
      return video ? video.domElement() : null;
    };

    /**
    * Returns the width, in pixels, of the Subscriber video.
    *
    * @method #videoWidth
    * @memberOf Subscriber
    * @return {Number} the width, in pixels, of the Subscriber video.
    */
    this.videoWidth = () => {
      const video = _widgetView && _widgetView.video();
      return video ? video.videoWidth() : undefined;
    };

    /**
    * Returns the height, in pixels, of the Subscriber video.
    *
    * @method #videoHeight
    * @memberOf Subscriber
    * @return {Number} the width, in pixels, of the Subscriber video.
    */
    this.videoHeight = () => {
      const video = _widgetView && _widgetView.video();
      return video ? video.videoHeight() : undefined;
    };

    this._subscribeToSelf = async () => {
      const publisher = _session.getPublisherForStream(_stream);
      if (!(publisher && publisher._.webRtcStream())) {
        connectivityState.fail({
          payload: {
            reason: 'streamNotFound',
          },
          error: otError(
            errors.STREAM_DESTROYED,
            new Error('Tried to subscribe to a local publisher, but its stream no longer exists')
          ),
        });
      } else {
        _pcConnected.resolve();

        try {
          await onRemoteStreamAdded(publisher._.webRtcStream());
        } catch (err) {
          logging.error(err);
        }
      }
    };

    this._setupPeerConnection = async ({ peerPriority, send, log, logQoS }) => {
      if (_properties.testNetwork) {
        this.setAudioVolume(0);
      }

      if (getAllPeerConnections().length === 0) {
        // @todo The subscribers states should be something like:
        // disconnected || connecting || reconnecting || connected || destroyed
        // the state of peer connections belong to the peer connection itself
        _state.set('Connecting');
      }

      peerConnections[peerPriority] = new Promise((resolve, reject) => {
        _session._.getIceConfig()
          .then((iceConfig) => {
            if (iceConfig.needRumorIceServersFallback) {
              iceConfig.servers = [...(fallbackIceServers || []), ...(iceConfig.servers || [])];
            }

            const props = {
              iceConfig,
              subscriberId: this.widgetId,
              send,
              logAnalyticsEvent: log,
              p2p: _session.sessionInfo.p2pEnabled,
            };

            if (Object.prototype.hasOwnProperty.call(_properties, 'codecFlags')) {
              props.codecFlags = _properties.codecFlags;
            }
            const peerConnection = new Subscriber.SubscriberPeerConnection(props);

            peerConnection.once('iceConnected', _pcConnected.resolve);
            peerConnection.once('error', _pcConnected.reject);

            if (_currentPeerConnectionEvents) {
              _currentPeerConnectionEvents.removeAll();
              const onDisconnected = () => {
                peerConnection.off('error', onDisconnected);
                peerConnection.off('disconnected', onDisconnected);
                delete peerConnections[peerPriority];
              };
              peerConnection.on('error', onDisconnected);
              peerConnection.on('disconnected', onDisconnected);
            }

            _currentPeerConnectionEvents = eventHelper(peerConnection);

            _currentPeerConnectionEvents.on('disconnected', onPeerDisconnected);
            _currentPeerConnectionEvents.on('error', onPeerConnectionFailure);
            _currentPeerConnectionEvents.on('remoteStreamAdded', async (...args) => {
              try {
                await onRemoteStreamAdded(...args);
              } catch (err) {
                logging.error(err);
              }
            });
            _currentPeerConnectionEvents.on('remoteStreamRemoved', onRemoteStreamRemoved);
            _currentPeerConnectionEvents.on('signalingStateStable', async () => {
              _subscriber.trigger('signalingStateStable');
              const video = _widgetView && _widgetView.video();
              if (video && _webRTCStream) {
                try {
                  await video.rebind();
                } catch (err) {
                  logging.error('Could not bind to stream', err);
                }

                if (_audioLevelSampler && 'webRTCStream' in _audioLevelSampler
                    && _webRTCStream.getAudioTracks().length > 0) {
                  _audioLevelSampler.webRTCStream(_webRTCStream);
                }
              }
            });

            peerConnection.on('qos', logQoS);
            _currentPeerConnectionEvents.on('iceConnectionStateChange', onIceConnectionStateChange);
            _currentPeerConnectionEvents.on('iceRestartSuccess', onIceRestartSuccess);
            _currentPeerConnectionEvents.on('iceRestartFailure', onIceRestartFailure);
            _currentPeerConnectionEvents.on('remoteVideoSupported', onRemoteVideoSupported);

            // initialize the peer connection AFTER we've added the event listeners
            peerConnection.init((err) => {
              if (err) {
                reject(err);
              } else {
                resolve(peerConnection);
              }
            });

            _currentPeerConnectionEvents.once('remoteStreamAdded', () => {
              if (destroyAudioLevelBehaviour) {
                destroyAudioLevelBehaviour();
                destroyAudioLevelBehaviour = undefined;
              }

              // prefer the audioLevelSampler (more efficient and better responsiveness)

              if (hasRemoteStreamsWithWebAudio()) {
                try {
                  _audioLevelSampler = new WebAudioAudioLevelSampler(audioContext());
                } catch (e) {
                  logging.warn('Failed to get AudioContext()', e);
                }
              }

              if (!_audioLevelSampler && Subscriber.hasAudioOutputLevelStatCapability()) {
                _audioLevelSampler = new GetStatsAudioOutputLevelSampler(peerConnection.getStats);
              }

              if (_audioLevelSampler) {
                ({ destroyAudioLevelBehaviour } = audioLevelBehaviour({ subscriber: this, audioLevelSampler: _audioLevelSampler }));
              } else {
                Object.defineProperty(
                  this,
                  'loudness',
                  { value: undefined, configurable: true, writable: false }
                );
                logging.error('No suitable audio level samplers found, audio level visualisation will not work');
              }
            });
          })
          .catch(reject);
      });
      return peerConnections[peerPriority];
    };

    /**
    * Restricts the frame rate of the Subscriber's video stream, when you pass in
    * <code>true</code>. When you pass in <code>false</code>, the frame rate of the video stream
    * is not restricted.
    * <p>
    * When the frame rate is restricted, the Subscriber video frame will update once or less per
    * second.
    * <p>
    * This feature is only available in sessions that use the OpenTok Media Router (sessions with
    * the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
    * set to routed), not in sessions with the media mode set to relayed. In relayed sessions,
    * calling this method has no effect.
    * <p>
    * Restricting the subscriber frame rate has the following benefits:
    * <ul>
    *    <li>It reduces CPU usage.</li>
    *    <li>It reduces the network bandwidth consumed.</li>
    *    <li>It lets you subscribe to more streams simultaneously.</li>
    * </ul>
    * <p>
    * Reducing a subscriber's frame rate has no effect on the frame rate of the video in
    * other clients.
    *
    * @param {Boolean} value Whether to restrict the Subscriber's video frame rate
    * (<code>true</code>) or not (<code>false</code>).
    *
    * @return {Subscriber} The Subscriber object. This lets you chain method calls, as in the
    * following:
    *
    * <pre>mySubscriber.restrictFrameRate(false).subscribeToAudio(true);</pre>
    *
    * @method #restrictFrameRate
    * @memberOf Subscriber
    */
    this.restrictFrameRate = (val) => {
      logging.debug(`OT.Subscriber.restrictFrameRate(${val})`);

      logAnalyticsEvent('restrictFrameRate', val.toString(), { streamId: _stream.id });

      if (_session.sessionInfo.p2pEnabled) {
        logging.warn('OT.Subscriber.restrictFrameRate: Cannot restrictFrameRate on a P2P session');
      }

      if (typeof val !== 'boolean') {
        logging.error(
          `OT.Subscriber.restrictFrameRate: expected a boolean value got a ${typeof val}`
        );
      } else {
        _frameRateRestricted = val;
        _stream.setRestrictFrameRate(val);
      }
      return this;
    };

    this.on('styleValueChanged', updateChromeForStyleChange, this);

    this._ = {
      async getDataChannel(label, options, completion) {
        // @fixme this will fail if it's called before we have a SubscriberPeerConnection.
        // I.e. before we have a publisher connection.
        // @todo what do we do with p2p -> mantis?
        if (!getPriorityPeerConnection()) {
          completion(
            new OTHelpers.Error('Cannot create a DataChannel before there is a publisher connection.')
          );

          return;
        }

        (await getPriorityPeerConnection()).getDataChannel(label, options, completion);
      },

      async iceRestart() {
        const peerConnection = await getPriorityPeerConnection();
        if (!peerConnection) {
          logging.debug('Subscriber: Skipping ice restart, we have no peer connection');
        } else {
          logResubscribe('Attempt');
          logging.debug('Subscriber: iceRestart attempt');
          peerConnection.iceRestart();
        }
      },

      unblockAudio: () => _widgetView && _widgetView.unblockAudio(),
      webRtcStream: () => _webRTCStream,

      privateEvents: new EventEmitter(),
    };

    _state = new SubscribingState(stateChangeFailed);

    logging.debug(`OT.Subscriber: subscribe to ${_stream.id}`);

    _state.set('Init');

    if (!_stream) {
      // @todo error
      logging.error('OT.Subscriber: No stream parameter.');
      return false;
    }

    _streamEventHandlers = {
      updated: streamUpdated,
    };

    _stream.on(_streamEventHandlers, this);

    _properties.name = _properties.name || _stream.name;
    _properties.classNames = 'OT_root OT_subscriber';

    if (_properties.style) {
      this.setStyle(_properties.style, null, true);
    }

    _latestPositiveVolume = DEFAULT_AUDIO_VOLUME;
    _properties.subscribeToVideo = castToBoolean(_properties.subscribeToVideo, true);
    _properties.subscribeToAudio = castToBoolean(_properties.subscribeToAudio, true);
    this.subscribeToAudio(_properties.subscribeToAudio);

    this.setAudioVolume(determineAudioVolume(_properties));

    _widgetView = new Subscriber.WidgetView(targetElement, { ..._properties, widgetType: 'subscriber' });
    _widgetView.on('error', onVideoError);
    _widgetView.on('audioBlocked', () => this.trigger('audioBlocked'));
    _widgetView.on('audioUnblocked', () => this.trigger('audioUnblocked'));

    this.id = _widgetView.domId();
    _domId = _widgetView.domId();
    this.element = _widgetView.domElement;

    _widgetView.on('videoElementCreated', (element) => {
      const event = new Events.VideoElementCreatedEvent(element);
      const self = this;
      if (!_loaded) {
        this.once('loaded', () => {
          self.dispatchEvent(event);
        });
      } else {
        this.dispatchEvent(event);
      }
    });

    if (this.element) {
      // Only create the chrome if there is an element to insert it in
      // for insertDefautlUI:false we don't create the chrome
      _createChrome.call(this);
    }

    let channelsToSubscribeTo;

    if (_properties.subscribeToVideo || _properties.subscribeToAudio) {
      const audio = _stream.getChannelsOfType('audio');
      const video = _stream.getChannelsOfType('video');

      channelsToSubscribeTo = audio.map(channel => ({
        id: channel.id,
        type: channel.type,
        active: _properties.subscribeToAudio,
      })).concat(video.map((channel) => {
        const props = {
          id: channel.id,
          type: channel.type,
          active: _properties.subscribeToVideo,
          restrictFrameRate: _properties.restrictFrameRate !== undefined ?
            _properties.restrictFrameRate : false,
        };

        if (_properties.preferredFrameRate !== undefined) {
          props.preferredFrameRate = parseFloat(_properties.preferredFrameRate);
        }

        if (_properties.preferredHeight !== undefined) {
          props.preferredHeight = parseInt(_properties.preferredHeight, 10);
        }

        if (_properties.preferredWidth !== undefined) {
          props.preferredWidth = parseInt(_properties.preferredWidth, 10);
        }

        return props;
      }));
    }

    const shouldSubscribeToSelf = !_properties.testNetwork && isLocalStream(_stream, _session);

    connectivityState.beginConnect();

    if (shouldSubscribeToSelf) {
      // bypass rumor etc and just subscribe locally
      this._subscribeToSelf();
      return this;
    }

    socket.subscriberCreate(
      _stream.id,
      _widgetId, // subscriberId
      channelsToSubscribeTo,
      (err, message) => {
        // when the publisher is destroyed before we subscribe, chances are we have been told about
        // before we get the subscriberCreate error, so this can be ignored.
        if (err && !connectivityState.is('disconnected')) {
          onSubscriberCreateError(err);
        }
        fallbackIceServers = parseIceServers(message);
      }
    );

    /**
    * Dispatched when the subscriber's audio is blocked because of the
    * browser's autoplay policy.
    *
    * @see <a href="OT.html#unblockAudio">OT.unblockAudio()</a>
    * @see <a href="Subscriber.html#isAudioBlocked">Subscriber.isAudioBlocked()</a>
    * @see The <a href="#event:audioUnblocked">audioUnblocked</a> event
    * @see The <code>style.audioBlockedDisplayMode</code> property of the
    * <code>options</code> parameter of the
    * <a href="Session.html#subscribe">Session.subscribe()</a> method)
    *
    * @name audioBlocked
    * @event
    * @memberof Subscriber
    */

    /**
    * Dispatched when the subscriber's audio is unblocked after
    * being paused because of the browser's autoplay policy.
    * <p>
    * Subscriber audio is unblocked when any of the following occurs:
    * <ul>
    *  <li>
    *    The user clicks the default Subscriber audio playback icon
    *  </li>
    *  <li>
    *     The <a href="OT.html#unblockAudio">OT.unblockAudio()</a>
    *     method is called in response to an HTML element dispatching
    *     a <code>click</code> event (if you have disabled the default
    *     audio playback icon)
    *  </li>
    *  <li>
    *     The local client gains access to the camera or microphone
    *     (for instance, in response to a successful call to
    *     <code>OT.initPublisher()</code>).
    *  </li>
    * </ul>
    *
    * @see <a href="OT.html#unblockAudio">OT.unblockAudio()</a>
    * @see The <a href="l#event:audioBlocked">audioBlocked</a> event
    * @see The <code>style.audioBlockedDisplayMode</code> property of the
    * <code>options</code> parameter of the
    * <a href="Session.html#subscribe">Session#subscribe()</a> method)
    *
    * @name audioUnblocked
    * @event
    * @memberof Subscriber
    */

    /**
    * Dispatched periodically to indicate the subscriber's audio level. The event is dispatched
    * up to 60 times per second, depending on the browser. The <code>audioLevel</code> property
    * of the event is audio level, from 0 to 1.0. See {@link AudioLevelUpdatedEvent} for more
    * information.
    * <p>
    * The following example adjusts the value of a meter element that shows volume of the
    * subscriber. Note that the audio level is adjusted logarithmically and a moving average
    * is applied:
    * <pre>
    * var movingAvg = null;
    * subscriber.on('audioLevelUpdated', function(event) {
    *   if (movingAvg === null || movingAvg &lt;= event.audioLevel) {
    *     movingAvg = event.audioLevel;
    *   } else {
    *     movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
    *   }
    *
    *   // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
    *   var logLevel = (Math.log(movingAvg) / Math.LN10) / 1.5 + 1;
    *   logLevel = Math.min(Math.max(logLevel, 0), 1);
    *   document.getElementById('subscriberMeter').value = logLevel;
    * });
    * </pre>
    * <p>This example shows the algorithm used by the default audio level indicator displayed
    * in an audio-only Subscriber.
    *
    * @name audioLevelUpdated
    * @event
    * @memberof Subscriber
    * @see AudioLevelUpdatedEvent
    */

    /**
    * Dispatched when the video for the subscriber is disabled.
    * <p>
    * The <code>reason</code> property defines the reason the video was disabled. This can be set to
    * one of the following values:
    * <p>
    *
    * <ul>
    *
    *   <li><code>"codecNotSupported"</code> &mdash; The client's browser does not support the
    *   video codec used by the stream. For example, in Safari if you connect to a
    *   <a href="https://tokbox.com/developer/guides/create-session/#media-mode">routed session</a>
    *   in a <a href="https://tokbox.com/developer/sdks/js/safari/#step1">non-Safari OpenTok
    *   project</a>, and you try to subscribe to a stream that includes video, the Subscriber
    *   will dispatch a <code>videoDisabled</code> event with the <code>reason</code> property
    *   set to <code>"codecNotSupported"</code>. (In routed sessions in a non-Safari project,
    *   streams use the VP8 video codec, which is not supported in Safari.) The subscriber
    *   element will also display a "Video format not supported" message. (See
    *   <a href="OT.html#getSupportedCodecs">OT.getSupportedCodecs()</a>.)</li>
    *
    *   <li><code>"publishVideo"</code> &mdash; The publisher stopped publishing video by calling
    *   <code>publishVideo(false)</code>.</li>
    *
    *   <li><code>"quality"</code> &mdash; The OpenTok Media Router stopped sending video
    *   to the subscriber based on stream quality changes. This feature of the OpenTok Media
    *   Router has a subscriber drop the video stream when connectivity degrades. (The subscriber
    *   continues to receive the audio stream, if there is one.)
    *   <p>
    *   Before sending this event, when the Subscriber's stream quality deteriorates to a level
    *   that is low enough that the video stream is at risk of being disabled, the Subscriber
    *   dispatches a <code>videoDisableWarning</code> event.
    *   <p>
    *   If connectivity improves to support video again, the Subscriber object dispatches
    *   a <code>videoEnabled</code> event, and the Subscriber resumes receiving video.
    *   <p>
    *   By default, the Subscriber displays a video disabled indicator when a
    *   <code>videoDisabled</code> event with this reason is dispatched and removes the indicator
    *   when the <code>videoEnabled</code> event with this reason is dispatched. You can control
    *   the display of this icon by calling the <code>setStyle()</code> method of the Subscriber,
    *   setting the <code>videoDisabledDisplayMode</code> property(or you can set the style when
    *   calling the <code>Session.subscribe()</code> method, setting the <code>style</code> property
    *   of the <code>properties</code> parameter).
    *   <p>
    *   This feature is only available in sessions that use the OpenTok Media Router (sessions with
    *   the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
    *   set to routed), not in sessions with the media mode set to relayed.
    *   <p>
    *   You can disable this audio-only fallback feature, by setting the
    *   <code>audioFallbackEnabled</code> property to <code>false</code> in the options you pass
    *   into the <code>OT.initPublisher()</code> method on the publishing client. (See
    *   <a href="OT.html#initPublisher">OT.initPublisher()</a>.)
    *   </li>
    *
    *   <li><code>"subscribeToVideo"</code> &mdash; The subscriber started or stopped subscribing to
    *   video, by calling <code>subscribeToVideo(false)</code>.
    *   </li>
    *
    * </ul>
    *
    * @see VideoEnabledChangedEvent
    * @see <a href="Subscriber.html#event:videoDisableWarning">videoDisableWarning</a> event
    * @see <a href="Subscriber.html#event:videoEnabled">videoEnabled</a> event
    * @name videoDisabled
    * @event
    * @memberof Subscriber
    */

    /**
    * Dispatched when the OpenTok Media Router determines that the stream quality has degraded
    * and the video will be disabled if the quality degrades more. If the quality degrades further,
    * the Subscriber disables the video and dispatches a <code>videoDisabled</code> event.
    * <p>
    * By default, the Subscriber displays a video disabled warning indicator when this event
    * is dispatched (and the video is disabled). You can control the display of this icon by
    * calling the <code>setStyle()</code> method and setting the
    * <code>videoDisabledDisplayMode</code> property (or you can set the style when calling
    * the <code>Session.subscribe()</code> method and setting the <code>style</code> property
    * of the <code>properties</code> parameter).
    * <p>
    * This feature is only available in sessions that use the OpenTok Media Router (sessions with
    * the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
    * set to routed), not in sessions with the media mode set to relayed.
    *
    * @see Event
    * @see <a href="Subscriber.html#event:videoDisabled">videoDisabled</a> event
    * @see <a href="Subscriber.html#event:videoDisableWarningLifted">videoDisableWarningLifted</a> event
    * @name videoDisableWarning
    * @event
    * @memberof Subscriber
    */

    /**
    * Dispatched when the Subscriber's video element is created. Add a listener for this event when
    * you set the <code>insertDefaultUI</code> option to <code>false</code> in the call to the
    * <a href="Session.html#subscribe">Session.subscribe()</a> method. The <code>element</code>
    * property of the event object is a reference to the Subscriber's <code>video</code> element
    * (or in Internet Explorer the <code>object</code> element containing the video). Add it to
    * the HTML DOM to display the video. When you set the <code>insertDefaultUI</code> option to
    * <code>false</code>, the <code>video</code> (or <code>object</code>) element is not automatically
    * inserted into the DOM.
    * <p>
    * Add a listener for this event only if you have set the <code>insertDefaultUI</code> option to
    * <code>false</code>. If you have not set <code>insertDefaultUI</code> option to
    * <code>false</code>, do not move the <code>video</code> (or <code>object</code>) element in
    * in the HTML DOM. Doing so causes the Subscriber object to be destroyed.
    *
    * @name videoElementCreated
    * @event
    * @memberof Subscriber
    * @see VideoElementCreatedEvent
    */

    /**
    * Dispatched when the OpenTok Media Router determines that the stream quality has improved
    * to the point at which the video being disabled is not an immediate risk. This event is
    * dispatched after the Subscriber object dispatches a <code>videoDisableWarning</code> event.
    * <p>
    * This feature is only available in sessions that use the OpenTok Media Router (sessions with
    * the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
    * set to routed), not in sessions with the media mode set to relayed.
    *
    * @see Event
    * @see <a href="Subscriber.html#event:videoDisableWarning">videoDisableWarning</a> event
    * @see <a href="Subscriber.html#event:videoDisabled">videoDisabled</a> event
    * @name videoDisableWarningLifted
    * @event
    * @memberof Subscriber
    */

    /**
    * Dispatched when the OpenTok Media Router resumes sending video to the subscriber
    * after video was previously disabled.
    * <p>
    * The <code>reason</code> property defines the reason the video was enabled. This can be set to
    * one of the following values:
    * <p>
    *
    * <ul>
    *
    *   <li><code>"codecChanged"</code> &mdash; The subscriber video was enabled after
    *   a codec change from an incompatible codec.</li>
    *
    *   <li><code>"publishVideo"</code> &mdash; The publisher started publishing video by calling
    *   <code>publishVideo(true)</code>.</li>
    *
    *   <li><code>"quality"</code> &mdash; The OpenTok Media Router resumed sending video
    *   to the subscriber based on stream quality changes. This feature of the OpenTok Media
    *   Router has a subscriber drop the video stream when connectivity degrades and then resume
    *   the video stream if the stream quality improves.
    *   <p>
    *   This feature is only available in sessions that use the OpenTok Media Router (sessions with
    *   the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
    *   set to routed), not in sessions with the media mode set to relayed.
    *   </li>
    *
    *   <li><code>"subscribeToVideo"</code> &mdash; The subscriber started or stopped subscribing to
    *   video, by calling <code>subscribeToVideo(false)</code>.
    *   </li>
    *
    * </ul>
    *
    * <p>
    * To prevent video from resuming, in the <code>videoEnabled</code> event listener,
    * call <code>subscribeToVideo(false)</code> on the Subscriber object.
    *
    * @see VideoEnabledChangedEvent
    * @see <a href="Subscriber.html#event:videoDisabled">videoDisabled</a> event
    * @name videoEnabled
    * @event
    * @memberof Subscriber
    */

    /**
    * Sent when the subscriber's stream has been interrupted.
    * <p>
    * In response to this event, you may want to provide a user interface notification, to let the
    * user know that the audio-video stream is temporarily disconnected and that the app is trying
    * to reconnect to it.
    * <p>
    * If the client reconnects to the stream, the Subscriber object dispatches a
    * <code>connected</code> event. Otherwise, if the client cannot reconnect to the stream,
    * the Subscriber object dispatches a <code>destroyed</code> event.
    *
    * @name disconnected
    * @event
    * @memberof Subscriber
    * @see <a href="Subscriber.html#event:connected">connected event</a>
    * @see Event
    */

    /**
    * Sent when the subscriber's stream has resumed, after the Subscriber dispatches a
    * <code>disconnected</code> event.
    *
    * @name connected
    * @event
    * @memberof Subscriber
    * @see <a href="Subscriber.html#event:disconnected">disconnected event</a>
    * @see Event
    */

    /**
    * Dispatched when the Subscriber element is removed from the HTML DOM. When this event is
    * dispatched, you may choose to adjust or remove HTML DOM elements related to the subscriber.
    * <p>
    * To prevent the Subscriber from being removed from the DOM when the stream is destroyed,
    * listen for the <a href="Session.html#event:streamDestroyed">streamDestroyed event</a>
    * dispatched by the Session object. The <code>streamDestroyed</code> event dispatched by the
    * Session object is cancelable, and calling the <code>preventDefault()</code> method of the
    * event object prevents Subscribers of the stream from being removed from the HTML DOM.
    *
    * @see Event
    * @name destroyed
    * @event
    * @memberof Subscriber
    */

    /**
    * Dispatched when the video dimensions of the video change. This can occur when the
    * <code>stream.videoType</code> property is set to <code>"screen"</code> (for a screen-sharing
    * video stream), when the user resizes the window being captured. It can also occur if the video
    * is being published by a mobile device and the user rotates the device (causing the camera
    * orientation to change). This event object has a <code>newValue</code> property and an
    * <code>oldValue</code> property, representing the new and old dimensions of the video.
    * Each of these has a <code>height</code> property and a <code>width</code> property,
    * representing the height and width, in pixels.
    * @name videoDimensionsChanged
    * @event
    * @memberof Subscriber
    * @see VideoDimensionsChangedEvent
    */

    return this;
  };

  Subscriber.hasAudioOutputLevelStatCapability = hasAudioOutputLevelStatCapability;
  Subscriber.WidgetView = WidgetView;
  Subscriber.SubscriberPeerConnection = SubscriberPeerConnection;

  return Subscriber;
};
