const SDPHelpers = require('./sdp_helpers.js');

module.exports = ({
  RTCPeerConnection,
  env,
  stream,
  codecFlags,
}) => {
  if (env.isLegacyEdge) {
    // Edge supports all codecs and the following test doesn't work
    return true;
  }

  let localPc;
  let remotePc;

  const cleanup = () => {
    try { localPc.close(); } catch (e) {} // eslint-disable-line no-empty
    try { remotePc.close(); } catch (e) {} // eslint-disable-line no-empty
    localPc = null;
    remotePc = null;
  };

  try {
    const pcConfig = {
      iceServers: [],
    };
    if (env.name === 'Chrome' && env.version > 71) {
      pcConfig.sdpSemantics = 'plan-b';
    }
    localPc = new RTCPeerConnection(pcConfig);
    remotePc = new RTCPeerConnection(pcConfig);
    localPc.addStream(stream);
  } catch (e) {
    cleanup();
    return Promise.resolve(false);
  }

  return localPc.createOffer()
    .then((offer) => {
      const newOffer = { type: offer.type, sdp: offer.sdp };

      Object.keys(codecFlags).forEach((codec) => {
        if (!codecFlags[codec]) {
          newOffer.sdp = SDPHelpers.removeVideoCodec(newOffer.sdp, codec);
        }
      });

      return remotePc.setRemoteDescription(newOffer);
    })
    .then(() => true)
    .catch(() => false)
    .then((result) => {
      cleanup();
      return result;
    });
};
