const clone = require('lodash/clone');

const env = require('../helpers/env.js');
const filterIceServers = require('./filterIceServers.js');
const logging = require('./log')('createPeerConnection');

module.exports = function createPeerConnection({
  window,
  config: originalConfig,
  constraints,
}) {
  logging.debug('called');

  const config = clone(originalConfig);

  config.iceServers = filterIceServers(env, config.iceServers);
  if (env.name === 'Chrome' && env.version > 71) {
    config.sdpSemantics = 'plan-b';
  }
  const pc = new window.RTCPeerConnection(config, constraints);

  return Promise.resolve(pc);
};
